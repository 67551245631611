import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { TagDetailQuery } from '../../graphql-types';

import Layout from '../components/Layout';
import Checklists from '../components/Checklists';
import Icon from '../components/Icon';
import LinkLightAsAnchor from '../components/links/LinkLight/LinkLightAsAnchor';

import './o-checklists-heading.scss';
import './o-suggest-a-checklist.scss';
import SEO from '../containers/SEO';

interface TagTemplateContext {
  tag: string;
}

interface Props {
  data: TagDetailQuery;
  pageContext: TagTemplateContext;
}

const TagTemplate: FC<Props> = ({ data, pageContext }) => {
  const { checklists } = data;
  const { tag } = pageContext;

  return (
    <Layout>
      <SEO title={`#${tag}`} description={`The checklists that have been tagged under #${tag}`} />
      <div className="u-padding-ends-xlarge@md-up u-padding-ends-large@sm-down">
        <div className="o-checklists-heading">
          <h2 className="u-margin-bottom-0">#{tag}</h2>
          <LinkLightAsAnchor
            className="o-suggest-a-checklist"
            href="https://github.com/checkops/checklists/new/master/checklists?filename=checklists/change-this-filename.md&value=---%0Atitle%3A%20Title%20of%20Your%20Checklist%0Acategory%3A%20Change%20this%20value%20with%20one%20of%20them%3B%20%22Design%22%2C%20%22Front-End%22%2C%20%22Back-End%22%2C%20%22Mobile%22%2C%20%22DevOps%20%22General%22%0Adate%3A%20%22YYYY-MM-DD%22%0Atags%3A%20[%27update%27%2C%20%27with%27%2C%20%27your%27%2C%20%27own%27%2C%20%27good-tags%27]%0Adescription%3A%20Please%20give%20a%20description%20to%20your%20checklist%0A---%0A%0A-%20[%20]%20Provide%20a%20to-do%20%20%0APlease%20add%20a%20description%20to%20this%20to-do%20if%20necessary%0A%0A-%20[%20]%20Second%20step"
          >
            Suggest a Checklist{' '}
            <Icon className="o-suggest-a-checklist__icon" name="external-link" ariaHidden />
          </LinkLightAsAnchor>
        </div>
        <div className="u-text-style-large-body u-margin-top-xsmall">
          There {checklists.totalCount > 1 ? 'are' : 'is'} {checklists.totalCount} of them and
          counting!
        </div>
        <Checklists className="u-margin-top-small" items={checklists.nodes} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query TagDetail($tag: String!) {
    checklists: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        ...Checklist
      }
    }
  }
`;

export default TagTemplate;
